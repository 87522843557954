import React from 'react'
import { Row, Container } from "react-bootstrap";
import Box from "./box";
import { graphql, useStaticQuery } from "gatsby";


const IntroBlocks = () => {
  const blocksQuery = graphql`
    query BoxItems {
      allMarkdownRemark {
        nodes {
          frontmatter {
            icon
            id
            slug
            title
            action
          }
          excerpt
        }
      }
    }
  `

  const blocks = useStaticQuery(blocksQuery).allMarkdownRemark.nodes

  return (
      <Container>
          <div className={'intro'} />
          <Row className={'justify-content-center features'}>
            { blocks.map((nodes) =>
                <Box key={ nodes.frontmatter.id }
                  icon={ nodes.frontmatter.icon }
                  name={ nodes.frontmatter.title }
                  description={ nodes.excerpt }
                  action={ nodes.frontmatter.action }
                  url={ nodes.frontmatter.url }
                />
              )}
          </Row>
      </Container>
  )
}

export default IntroBlocks