import React from "react"

const ParallaxImage = () => {
  return (
    <div id="index-parallax-image" data-bs-parallax-bg="true">

    </div>
  )
}


export default ParallaxImage