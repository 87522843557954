import React from "react"
import WelcomeBlock from "./welcome-block";
import IntroBlocks from "./intro-blocks";


const HomeBlocks = () => {

  return (
    <div className="features-boxed">
      <WelcomeBlock />
      <IntroBlocks />
    </div>
  )
}

export default HomeBlocks