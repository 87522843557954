import React from 'react'
import { Jumbotron, Container } from "react-bootstrap";


const WelcomeBlock = () => {
  const h1Styles = 'text-center d-xl-flex justify-content-xl-center align-items-xl-center'

  return (
    <Jumbotron>
      <h1 className={h1Styles}>WELCOME TO</h1>
      <h2 className="text-center">Letchworth and Baldock District Scouts</h2>
      <Container>
        <p className="text-center">We are one of the largest youth organisations in the local area, with nearly 800 young people involved in Beavers, Cubs, Scouts and Explorers.&nbsp;We have an active and cohesive district, with groups and sections working together to deliver fantastic events and engaging programmes for young people on a weekly basis.</p>
          <br/>
        <p className="text-center">We have a District Headquarters on Icknield Way and a fantastic camping ground and activity centre just outside Great Wymondley; which we share with the Guide Association.</p>
      </Container>
    </Jumbotron>
  )
}

export default WelcomeBlock







