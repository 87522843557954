import React from "react"

import '../utils/fontawesome';
import SEO from "../components/seo";
import TopNav from "../components/top-nav";
import ParallaxImage from "../components/parallax-image";
import SiteNavbar from "../components/nav";
import HomeBlocks from "../components/home-blocks";
import Footer from "../components/footer";
import { graphql, useStaticQuery } from "gatsby";

const IndexPage = () => {
  const indexData = useStaticQuery(graphql`
      query SiteIndexTitleQuery {
        site {
          siteMetadata {
            description
          }
        }
      }`);

  return (
    <>
      <TopNav siteTitle={ indexData.site.siteMetadata.description }/>
      <ParallaxImage />
      <SiteNavbar logo={false} />
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <HomeBlocks />
      <Footer />
    </>
  )

}

export default IndexPage