import PropTypes from "prop-types"
import React from "react"

import { Navbar, Container } from "react-bootstrap";

import Logo from "./nav/logo";

const TopNav = ({ siteTitle }) => {

  return (
    <Navbar bg="light" expand="md" sticky="top" variant="light" id="site-top-bar">
      <Container>
        <Navbar.Brand href="#home" id="site-top-bar-brand">
          <Logo/> {siteTitle}
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}

TopNav.propTypes = {
  siteTitle: PropTypes.string,
}

TopNav.defaultProps = {
  siteTitle: ``,
}

export default TopNav
